// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";

function ruleToJs(rule) {
  var variant = rule[0];
  if (variant >= -659583595) {
    if (variant >= 488687584) {
      if (variant >= 829240095) {
        var match = rule[1];
        return /* tuple */[
                match[0],
                Js_dict.fromList(List.map(ruleToJs, match[1]))
              ];
      } else {
        return /* tuple */[
                "boxShadow",
                rule[1]
              ];
      }
    } else if (variant >= -434952966) {
      var match$1 = rule[1];
      var value = match$1[1];
      var name = match$1[0];
      if (name === "content") {
        return /* tuple */[
                name,
                value === "" ? "\"\"" : value
              ];
      } else {
        return /* tuple */[
                name,
                value
              ];
      }
    } else {
      return /* tuple */[
              "transition",
              rule[1]
            ];
    }
  } else if (variant >= -737064851) {
    return /* tuple */[
            "textShadow",
            rule[1]
          ];
  } else {
    return /* tuple */[
            "animation",
            rule[1]
          ];
  }
}

function rulesToJson(rules) {
  return Js_dict.fromList(List.map(ruleToJs, rules));
}

export {
  ruleToJs ,
  rulesToJson ,
  
}
/* No side effect */
