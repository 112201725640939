// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as $$String from "bs-platform/lib/es6/string.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as EnJson from "./translations/en.json";
import * as FrJson from "./translations/fr.json";

var fr = FrJson.default;

var en = EnJson.default;

function translationsToDict(translations) {
  return Belt_Array.reduce(translations, { }, (function (dict, entry) {
                var match = entry.message;
                var tmp;
                tmp = (match == null) || match === "" ? entry.defaultMessage : match;
                dict[entry.id] = tmp;
                return dict;
              }));
}

function toString(param) {
  if (param) {
    return "fr";
  } else {
    return "en";
  }
}

function toPath(param) {
  if (param) {
    return "/fr";
  } else {
    return "";
  }
}

function translations(param) {
  if (param) {
    return fr;
  } else {
    return en;
  }
}

function other(param) {
  if (param) {
    return /* En */0;
  } else {
    return /* Fr */1;
  }
}

function fromString(param) {
  if (param === "fr") {
    return /* Fr */1;
  } else {
    return /* En */0;
  }
}

function get(prim) {
  return prim.locale;
}

function pref(intl, url) {
  var param = fromString(intl.locale);
  return (
          param ? "/fr" : ""
        ) + url;
}

function get_other(intl) {
  var param = fromString(intl.locale);
  if (param) {
    return /* En */0;
  } else {
    return /* Fr */1;
  }
}

function switch_lang(path) {
  if (path === "/") {
    return "/fr/";
  } else {
    var match = $$String.sub(path, 1, 2);
    if (match === "fr") {
      return $$String.sub(path, 3, path.length - 3 | 0);
    } else {
      return "/fr" + path;
    }
  }
}

export {
  fr ,
  en ,
  translationsToDict ,
  toString ,
  toPath ,
  translations ,
  other ,
  fromString ,
  get ,
  pref ,
  get_other ,
  switch_lang ,
  
}
/* fr Not a pure module */
