// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as List from "bs-platform/lib/es6/list.js";
import * as Theme from "./Theme.bs.js";

function get_global_style(param) {
  Css.fontFace("Quattrocento", /* :: */[
        /* `url */[
          5843823,
          "/fonts/Quattrocento/Quattrocento-Regular.ttf"
        ],
        /* [] */0
      ], undefined, /* normal */812216871, /* () */0);
  Css.fontFace("Quattrocento", /* :: */[
        /* `url */[
          5843823,
          "/fonts/Quattrocento/Quattrocento-Bold.ttf"
        ],
        /* [] */0
      ], undefined, /* bold */-1055161979, /* () */0);
  return List.rev_append(Theme.make_global_scale(/* () */0), /* :: */[
              Css.selector("html", /* [] */0),
              /* :: */[
                Css.selector("body", /* :: */[
                      Css.backgroundColor(Theme.bg_color),
                      /* :: */[
                        Css.fontFamily(Theme.fontFamily),
                        /* :: */[
                          Css.fontSize(/* `calc */[
                                -1044768619,
                                /* tuple */[
                                  /* add */4846113,
                                  Theme.font_base_size,
                                  Theme.font_grow_factor
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]),
                /* :: */[
                  Css.selector("a", /* :: */[
                        Css.color(Css.currentColor),
                        /* :: */[
                          Css.textDecoration(/* underline */131142924),
                          /* :: */[
                            Css.textDecorationStyle(/* dotted */841979626),
                            /* :: */[
                              Css.textDecorationColor(Css.hex("4a4a4a")),
                              /* :: */[
                                Css.selector("&:hover", /* :: */[
                                      Css.textDecorationStyle(/* solid */12956715),
                                      /* [] */0
                                    ]),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]),
                  /* :: */[
                    Css.selector("h1, h2, h3, h4, h5", /* :: */[
                          Css.textAlign(/* left */-944764921),
                          /* [] */0
                        ]),
                    /* :: */[
                      Css.selector(".gatsby-highlight", /* :: */[
                            Css.fontSize(Css.em(0.8)),
                            /* [] */0
                          ]),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

export {
  get_global_style ,
  
}
/* Css Not a pure module */
