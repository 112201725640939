// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as List from "bs-platform/lib/es6/list.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as EClassNames from "./EClassNames.bs.js";
import * as Core from "@emotion/core";

function WithCss(Props) {
  var children = Props.children;
  return React.createElement(Core.ClassNames, {
              children: (function (emo) {
                  return Curry._1(children, (function (classNamesOpt, rules) {
                                var classNames = classNamesOpt !== undefined ? classNamesOpt : /* [] */0;
                                var rjs = $$Array.of_list(List.map(EClassNames.rulesToJson, rules));
                                var css_name = emo.css(rjs);
                                if (List.length(classNames) === 0) {
                                  return css_name;
                                } else {
                                  var cjs = $$Array.of_list(/* :: */[
                                        css_name,
                                        classNames
                                      ]);
                                  return emo.cx(cjs);
                                }
                              }));
                })
            });
}

var make = WithCss;

export {
  make ,
  
}
/* react Not a pure module */
