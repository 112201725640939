// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Theme from "../../Theme.bs.js";
import * as React from "react";
import * as Locale from "../../intl/Locale.bs.js";
import * as Gatsby from "gatsby";
import * as Helpers from "../../Helpers.bs.js";
import * as WithCss from "../../bindings/bs-emotion-core/src/WithCss.bs.js";
import * as ReactIntl from "react-intl";

var fixed_header_anim = Css.keyframes(/* :: */[
      /* tuple */[
        0,
        /* :: */[
          Css.transform(Css.translate(/* zero */-789508312, Css.pct(-100))),
          /* [] */0
        ]
      ],
      /* :: */[
        /* tuple */[
          100,
          /* :: */[
            Css.transform(Css.translate(/* zero */-789508312, /* zero */-789508312)),
            /* [] */0
          ]
        ],
        /* [] */0
      ]
    ]);

var header_000 = Css.borderBottom(Css.px(5), /* dashed */568403505, Css.black);

var header_001 = /* :: */[
  Css.width(Css.pct(100)),
  /* :: */[
    Css.paddingTop(Css.em(1)),
    /* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.zIndex(100),
        /* :: */[
          Css.selector("a", /* :: */[
                Css.textDecoration(/* none */-922086728),
                /* [] */0
              ]),
          /* :: */[
            Css.selector("&.fixed", /* :: */[
                  Css.position(/* sticky */188263721),
                  /* :: */[
                    Css.top(/* zero */-789508312),
                    /* :: */[
                      Css.left(/* zero */-789508312),
                      /* :: */[
                        Css.paddingTop(/* zero */-789508312),
                        /* :: */[
                          Css.fontSize(Css.em(0.7)),
                          /* :: */[
                            Css.backgroundColor(Theme.bg_color),
                            /* :: */[
                              Css.animation(500, undefined, undefined, undefined, undefined, undefined, undefined, fixed_header_anim),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]),
            /* [] */0
          ]
        ]
      ]
    ]
  ]
];

var header = /* :: */[
  header_000,
  header_001
];

var title_box_000 = Css.display(/* inlineFlex */53323314);

var title_box_001 = /* :: */[
  Css.flexDirection(/* row */5693978),
  /* :: */[
    Css.flexWrap(/* wrap */-822134326),
    /* :: */[
      Css.justifyContent(/* spaceAround */-485895757),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.alignContent(/* center */98248149),
          /* :: */[
            Css.selector(".fixed &", /* :: */[
                  Css.width(Css.pct(100)),
                  /* [] */0
                ]),
            /* :: */[
              Css.selector("& h1", /* :: */[
                    Css.fontSize(Css.em(5)),
                    /* :: */[
                      Css.textAlign(/* center */98248149),
                      /* :: */[
                        Css.margin(/* zero */-789508312),
                        /* :: */[
                          Css.flexBasis(Css.pct(100)),
                          /* :: */[
                            Css.selector(".fixed &", /* :: */[
                                  Css.fontSize(Css.em(2)),
                                  /* :: */[
                                    Css.flexBasis(/* auto */-1065951377),
                                    /* [] */0
                                  ]
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var title_box = /* :: */[
  title_box_000,
  title_box_001
];

var menu_item_styles_000 = Css.display(/* inlineBlock */-147785676);

var menu_item_styles_001 = /* :: */[
  Css.padding(Css.px(10)),
  /* :: */[
    Css.paddingTop(Css.px(12)),
    /* :: */[
      Css.marginTop(Css.em(0.5)),
      /* :: */[
        Css.marginRight(Theme.menu_item_sep),
        /* :: */[
          Css.marginBottom(Css.em(0.8)),
          /* :: */[
            Css.border(Css.px(2), /* dotted */841979626, Css.transparent),
            /* :: */[
              Css.fontSize(Css.em(1.5)),
              /* :: */[
                Css.textTransform(/* uppercase */-415330030),
                /* :: */[
                  Css.selector("&.active", /* :: */[
                        Css.borderStyle(/* solid */12956715),
                        /* :: */[
                          Css.borderColor(Css.black),
                          /* :: */[
                            Css.backgroundColor(Css.black),
                            /* :: */[
                              Css.color(Css.white),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  /* :: */[
                    Css.selector(".fixed &", /* :: */[
                          Css.marginTop(/* zero */-789508312),
                          /* :: */[
                            Css.marginBottom(/* zero */-789508312),
                            /* [] */0
                          ]
                        ]),
                    /* :: */[
                      Css.transition(100, undefined, undefined, "border-color"),
                      /* :: */[
                        Css.selector("&:hover", /* :: */[
                              Css.borderStyle(/* dotted */841979626),
                              /* :: */[
                                Css.borderColor(Css.black),
                                /* [] */0
                              ]
                            ]),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var menu_item_styles = /* :: */[
  menu_item_styles_000,
  menu_item_styles_001
];

var menu_000 = Css.display(/* flex */-1010954439);

var menu_001 = /* :: */[
  Css.paddingLeft(Theme.menu_item_sep),
  /* :: */[
    Css.marginBottom(Css.em(0.3)),
    /* :: */[
      Css.flexDirection(/* row */5693978),
      /* :: */[
        Css.flexWrap(/* wrap */-822134326),
        /* :: */[
          Css.justifyContent(/* spaceEvenly */-1016061555),
          /* :: */[
            Css.selector("& a", menu_item_styles),
            /* :: */[
              Css.selector(".fixed &", /* :: */[
                    Css.marginTop(/* zero */-789508312),
                    /* :: */[
                      Css.marginBottom(/* zero */-789508312),
                      /* [] */0
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]
  ]
];

var menu = /* :: */[
  menu_000,
  menu_001
];

var page_description_000 = Css.margin(/* zero */-789508312);

var page_description_001 = /* :: */[
  Css.paddingTop(Css.rem(2)),
  /* :: */[
    Css.paddingBottom(Css.rem(2)),
    /* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.borderBottom(Css.px(5), /* dashed */568403505, Css.black),
        /* [] */0
      ]
    ]
  ]
];

var page_description = /* :: */[
  page_description_000,
  page_description_001
];

var sharp_000 = Css.display(/* inlineBlock */-147785676);

var sharp = /* :: */[
  sharp_000,
  /* [] */0
];

var des_text_000 = Css.display(/* inlineBlock */-147785676);

var des_text = /* :: */[
  des_text_000,
  /* [] */0
];

var S = {
  fixed_header_anim: fixed_header_anim,
  header: header,
  title_box: title_box,
  menu_item_styles: menu_item_styles,
  menu: menu,
  page_description: page_description,
  sharp: sharp,
  des_text: des_text
};

function Header(Props) {
  var title = Props.title;
  var page_description$1 = Props.page_description;
  var pathname = Props.pathname;
  var showLangSwitchOpt = Props.showLangSwitch;
  var showLangSwitch = showLangSwitchOpt !== undefined ? showLangSwitchOpt : true;
  var match = React.useState((function () {
          return false;
        }));
  var fixed = match[0];
  var activeClassName = "active";
  React.useEffect((function () {
          return ;
        }));
  var intl = ReactIntl.useIntl();
  return React.createElement(React.Fragment, {
              children: React.createElement(WithCss.make, {
                    children: (function (css) {
                        var tmp;
                        if (showLangSwitch) {
                          var className = Curry._2(css, undefined, /* :: */[
                                /* :: */[
                                  Css.position(/* absolute */-1013592457),
                                  /* :: */[
                                    Css.left(Css.pct(75)),
                                    /* :: */[
                                      Css.padding(Css.em(0.3)),
                                      /* :: */[
                                        Css.paddingTop(Css.em(0.2)),
                                        /* :: */[
                                          Css.paddingBottom(Css.em(0.2)),
                                          /* :: */[
                                            Css.border(Css.px(3), /* dotted */841979626, Css.black),
                                            /* :: */[
                                              Css.borderTopStyle(/* none */-922086728),
                                              /* :: */[
                                                Css.fontSize(Css.em(0.8)),
                                                /* :: */[
                                                  Css.textAlign(/* center */98248149),
                                                  /* :: */[
                                                    Css.width(Css.em(1.2)),
                                                    /* :: */[
                                                      Css.textTransform(/* uppercase */-415330030),
                                                      /* :: */[
                                                        Css.selector("&:hover", /* :: */[
                                                              Css.borderStyle(/* solid */12956715),
                                                              /* :: */[
                                                                Css.borderTopStyle(/* none */-922086728),
                                                                /* [] */0
                                                              ]
                                                            ]),
                                                        /* :: */[
                                                          Css.selector("& a", /* :: */[
                                                                Css.textDecoration(/* none */-922086728),
                                                                /* [] */0
                                                              ]),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]
                                                  ]
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ],
                                /* [] */0
                              ]);
                          tmp = React.createElement(Gatsby.Link, {
                                to: Locale.switch_lang(pathname),
                                children: React.createElement("span", {
                                      className: className
                                    }, Helpers.text(Locale.toString(Locale.get_other(intl))))
                              });
                        } else {
                          tmp = null;
                        }
                        return React.createElement(React.Fragment, {
                                    children: null
                                  }, React.createElement("header", {
                                        className: Curry._2(css, /* :: */[
                                              fixed ? "fixed" : "",
                                              /* [] */0
                                            ], /* :: */[
                                              header,
                                              /* [] */0
                                            ])
                                      }, React.createElement("div", {
                                            className: Curry._2(css, undefined, /* :: */[
                                                  title_box,
                                                  /* [] */0
                                                ])
                                          }, React.createElement("h1", undefined, Helpers.text(title)), React.createElement("nav", {
                                                className: Curry._2(css, undefined, /* :: */[
                                                      menu,
                                                      /* [] */0
                                                    ])
                                              }, React.createElement(Gatsby.Link, {
                                                    to: Locale.pref(intl, "/"),
                                                    activeClassName: activeClassName,
                                                    children: React.createElement(ReactIntl.FormattedMessage, {
                                                          id: "menu.blog",
                                                          defaultMessage: "Blog"
                                                        })
                                                  }), React.createElement(Gatsby.Link, {
                                                    to: Locale.pref(intl, "/about/"),
                                                    activeClassName: activeClassName,
                                                    children: React.createElement(ReactIntl.FormattedMessage, {
                                                          id: "menu.about",
                                                          defaultMessage: "AboutDef"
                                                        })
                                                  }), React.createElement(Gatsby.Link, {
                                                    to: Locale.pref(intl, "/origallery/"),
                                                    activeClassName: activeClassName,
                                                    children: React.createElement(ReactIntl.FormattedMessage, {
                                                          id: "menu.gallery",
                                                          defaultMessage: "Origallery"
                                                        })
                                                  })))), tmp, page_description$1 !== undefined ? React.createElement(React.Fragment, {
                                          children: React.createElement("h4", {
                                                className: Curry._2(css, undefined, /* :: */[
                                                      page_description,
                                                      /* [] */0
                                                    ])
                                              }, Helpers.text(page_description$1))
                                        }) : null);
                      })
                  })
            });
}

var make = Header;

export {
  S ,
  make ,
  
}
/* fixed_header_anim Not a pure module */
