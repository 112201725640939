// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Theme from "../../Theme.bs.js";
import * as React from "react";
import * as Header from "./Header.bs.js";
import * as Locale from "../../intl/Locale.bs.js";
import * as Styles from "../../Styles.bs.js";
import * as Helpers from "../../Helpers.bs.js";
import * as WithCss from "../../bindings/bs-emotion-core/src/WithCss.bs.js";
import * as GlobalCss from "../../bindings/bs-emotion-core/src/GlobalCss.bs.js";
import * as ReactIntl from "react-intl";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ReactHelmet from "react-helmet";

var content_000 = Css.boxSizing(/* borderBox */9307263);

var content_001 = /* :: */[
  Css.marginLeft(/* auto */-1065951377),
  /* :: */[
    Css.marginRight(/* auto */-1065951377),
    /* :: */[
      Css.marginTop(Css.rem(3)),
      /* :: */[
        Css.maxWidth(Theme.main_block_max_size),
        /* :: */[
          Css.width(Css.pct(100)),
          /* :: */[
            Css.textAlign(/* justify */62250832),
            /* :: */[
              Css.unsafe("textJustify", "inter-character"),
              /* :: */[
                Css.media(Theme.$$break(/* mobileOnly */-728877330), /* :: */[
                      Css.width(Css.pct(90)),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]
  ]
];

var content = /* :: */[
  content_000,
  content_001
];

var large_content_001 = /* :: */[
  /* :: */[
    Css.maxWidth(Theme.large_block_max_size),
    /* [] */0
  ],
  /* [] */0
];

var large_content = /* :: */[
  content,
  large_content_001
];

var S = {
  wrapper: /* [] */0,
  content: content,
  large_content: large_content
};

function Layout(Props) {
  var largeOpt = Props.large;
  var page_description = Props.page_description;
  var pathname = Props.pathname;
  var langOpt = Props.lang;
  var showLangSwitchOpt = Props.showLangSwitch;
  var children = Props.children;
  var large = largeOpt !== undefined ? largeOpt : false;
  var lang = langOpt !== undefined ? langOpt : "en";
  var showLangSwitch = showLangSwitchOpt !== undefined ? showLangSwitchOpt : true;
  var title = "The Naked Blog";
  var messages = Locale.translationsToDict(Locale.translations(Locale.fromString(lang)));
  return React.createElement(ReactIntl.IntlProvider, {
              locale: lang,
              messages: messages,
              children: React.createElement(React.Fragment, {
                    children: null
                  }, React.createElement(ReactHelmet.Helmet, {
                        children: null
                      }, React.createElement("html", {
                            lang: lang
                          }), React.createElement("title", undefined, Helpers.text(title)), React.createElement("meta", {
                            content: "Everything is awesome!",
                            name: "description"
                          })), React.createElement(WithCss.make, {
                        children: (function (css) {
                            var tmp = {
                              title: title,
                              pathname: pathname,
                              showLangSwitch: showLangSwitch
                            };
                            if (page_description !== undefined) {
                              tmp.page_description = Caml_option.valFromOption(page_description);
                            }
                            return React.createElement("div", {
                                        className: Curry._2(css, undefined, /* :: */[
                                              /* [] */0,
                                              /* [] */0
                                            ])
                                      }, React.createElement(GlobalCss.make, {
                                            styles: Styles.get_global_style(/* () */0)
                                          }), React.createElement(Header.make, tmp), React.createElement("main", {
                                            className: Curry._2(css, undefined, large ? large_content : /* :: */[
                                                    content,
                                                    /* [] */0
                                                  ])
                                          }, children), React.createElement("footer", undefined));
                          })
                      }))
            });
}

var make = Layout;

export {
  S ,
  make ,
  
}
/* content Not a pure module */
