// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as List from "bs-platform/lib/es6/list.js";

var page_title = Css.style(/* :: */[
      Css.textAlign(/* center */98248149),
      /* :: */[
        Css.textTransform(/* uppercase */-415330030),
        /* [] */0
      ]
    ]);

var font_base_size = Css.em(0.5);

var font_grow_factor = Css.vw(1.0);

function font_size_rem(level) {
  return Css.em(Math.pow(1.20, level));
}

function make_global_scale(param) {
  return List.map((function (i) {
                return Css.selector("h" + String(i), /* :: */[
                            Css.fontSize(Css.em(Math.pow(1.20, 6 - i | 0))),
                            /* [] */0
                          ]);
              }), /* :: */[
              1,
              /* :: */[
                2,
                /* :: */[
                  3,
                  /* :: */[
                    4,
                    /* :: */[
                      5,
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]);
}

var main_block_max_size = Css.ch(50);

var large_block_max_size = Css.ch(62);

function $$break(point) {
  if (point >= 588017280) {
    if (point >= 614173333) {
      return "(max-width: 700px)";
    } else {
      return "(max-width: 480px)";
    }
  } else if (point >= -186674237) {
    return "(max-width: 910px)";
  } else {
    return "(max-width: 1200px)";
  }
}

var menu_item_sep = Css.em(1);

var fontFamily = "'Quattrocento', serif";

var font_scale = 1.20;

var bg_color = Css.white;

export {
  fontFamily ,
  page_title ,
  font_base_size ,
  font_grow_factor ,
  font_scale ,
  font_size_rem ,
  make_global_scale ,
  main_block_max_size ,
  large_block_max_size ,
  $$break ,
  menu_item_sep ,
  bg_color ,
  
}
/* page_title Not a pure module */
