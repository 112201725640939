// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css/src/Css.js";
import * as $$Array from "bs-platform/lib/es6/array.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Webapi__Dom__Document from "bs-webapi/src/Webapi/Webapi__Dom/Webapi__Dom__Document.js";

var nothing = null;

function text(prim) {
  return prim;
}

var list = $$Array.of_list;

function array(arr) {
  return arr;
}

function nodeList(node) {
  return $$Array.to_list(node.list);
}

function onHover(styles) {
  return Css.selector("&:hover", styles);
}

function and_then(f, param) {
  if (param !== undefined) {
    return Curry._1(f, Caml_option.valFromOption(param));
  }
  
}

function opt_map(f, param) {
  if (param !== undefined) {
    return Caml_option.some(Curry._1(f, Caml_option.valFromOption(param)));
  }
  
}

function get_win(param) {
  return window;
}

function get_doc(param) {
  return Webapi__Dom__Document.asHtmlDocument(document);
}

function get_body(param) {
  return and_then((function (prim) {
                return Caml_option.nullable_to_opt(prim.body);
              }), Webapi__Dom__Document.asHtmlDocument(document));
}

function new_event_listener(name, handler, target) {
  var cleanup = function (param) {
    target.removeEventListener(name, handler);
    return /* () */0;
  };
  target.addEventListener(name, handler);
  return cleanup;
}

export {
  nothing ,
  text ,
  list ,
  array ,
  nodeList ,
  onHover ,
  and_then ,
  opt_map ,
  get_win ,
  get_doc ,
  get_body ,
  new_event_listener ,
  
}
/* nothing Not a pure module */
