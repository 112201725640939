// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as React from "react";
import * as EClassNames from "./EClassNames.bs.js";
import * as Core from "@emotion/core";

function GlobalCss(Props) {
  var styles = Props.styles;
  var styles$1 = [EClassNames.rulesToJson(styles)];
  return React.createElement(Core.Global, {
              styles: styles$1
            });
}

var make = GlobalCss;

export {
  make ,
  
}
/* react Not a pure module */
